import React from "react"
import { graphql } from "gatsby"
import { Parser } from "html-to-react"
import {
  linesToListItems,
  trailingslashit,
  linesToListComma,
} from "../components/helpers"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Parallax } from "react-parallax"
import Button from "react-bootstrap/Button"

import Image from "../components/image"
import SectionHeading from "../components/SectionHeading"
import Hero from "../components/Hero"
import CTAs from "../components/CTAs"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicePage = ({ data, pageContext, location }) => {
  const service = data.allButterPage.edges[0].node

  return (
    <Layout
      hireUs={false}
      hero={
        <Hero
          heading={service.title}
          subhead={service.excerpt}
          image={service.hero_background}
        />
      }
    >
      <SEO title={service.title} path={location.pathname} />

      <Container>
        <Row>
          <Col className="text-center py-5">
            <Image src={service.icon} className="mb-4" />
            <p className="service-body">{service.body}</p>
          </Col>
        </Row>
      </Container>

      <section className="serviceList">
        <div className="serviceList__background">
          <Parallax
            blur={{ min: -15, max: 15 }}
            bgImage={service.subservice_background}
            bgImageAlt="services"
            strength={500}
          >
            <div style={{ height: "800px" }} />
          </Parallax>
        </div>
        <Container>
          <SectionHeading
            heading={service.subservice_heading}
            className="mb-5 text-light"
          />
          <Row>
            {service.sub_services.map(sub => (
              <Col lg={4} md={12} className="py-3 py-lg-0 sub-services-margin">
                <div className="bg-light h-100 pt-4 px-3 border sub-services">
                  <h3>{sub.heading}</h3>
                  <hr />
                  <ul className={sub.capabilities}>
                    {sub.capabilities.split(",").map(capability => (
                      <li className="sub-services-item">
                        <span>{capability}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <Container as="section" fluid={true} className="text-center bg-gray py-5">
        <Row>
          <Col>
            <SectionHeading
              crown="Related Work"
              heading={service.our_work_heading}
            />
            <p>{service.our_work_body}</p>
            <CTAs ctas={service.our_work_ctas} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ServicePageBySlug($slug: String!) {
    allButterPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          hero_background
          icon
          slug
          title
          excerpt
          body
          subservice_heading
          subservice_background
          sub_services {
            capabilities
            heading
          }
          our_work_heading
          our_work_body
          our_work_ctas {
            label
            url
            icon
            type
          }
          our_service_ctas {
            label
            url
            icon
            type
          }
        }
      }
    }
  }
`

export default ServicePage
